import Wrapper from './common/Wrapper';

import Bubble from './common/Bubble';
import Phone from './common/Phone';
import LoopedVideo from './common/LoopedVideo';
import { superCutPoster, superCutVideo } from '../shared/constants';
import { H1, P1 } from './common/primitives';
import JoinWaitlist from './JoinWaitlist';

const Hero = ({ className }: { className?: string }) => {
  return (
    <Wrapper className={className}>
      <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 justify-between items-center py-8">
        <div className="w-[80%] sm:w-full lg:order-1 lg:basis-3/6 flex items-center justify-center">
          <div className="relative w-2/5 lg:mr-24 ">
            <Bubble className="absolute max-w-none w-auto h-[130%] top-0 bottom-0 left-0 right-0 -translate-y-[10%] lg:-translate-y-[10vh] sm:-translate-x-[32%]  -translate-x-[35%]" />
            <Phone
              glow={false}
              className="relative mx-auto lg:w-auto lg:max-w-none lg:h-[60vh] z-10"
            >
              <LoopedVideo
                src={superCutVideo}
                placeholderSrc={superCutPoster}
              />
            </Phone>
          </div>
        </div>
        <div className="lg:basis-3/6 relative z-10">
          <div className="text-center lg:text-left flex items-center lg:items-start flex-col space-y-6 lg:space-y-8 max-w-[420px]">
            <H1>Say hello to Dialect</H1>
            <P1>
              Rich, interactive mobile messaging and notifications. Join the
              waitlist for early access and the chance to mint a new kind of
              NFT.
            </P1>
            <p className="flex space-x-2 justify-center lg:justify-start">
              <JoinWaitlist textOnMobile />
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Hero;
