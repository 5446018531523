import { PopupButton } from '@typeform/embed-react';
import clsx from 'clsx';
import Head from 'next/head';
import { useContext } from 'react';
import Card from '../components/Card';
import CTA from '../components/CTA';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Hero from '../components/Hero';
import JoinWaitlist from '../components/JoinWaitlist';
import QRCodeModal from '../components/QRCodeModal';
import {
  CDN_URL,
  mintWithRevealNftVideo,
  notificationsDemoPoster,
  notificationsDemoVideo,
  smartMessageDemoVideo,
} from '../shared/constants';
import nftStickers from '../images/NFTStickers.png';
import smartMessage from '../images/SmartMessageAttached@2x.png';
import Link from './common/Link';
import LoopedVideo from './common/LoopedVideo';
import { H2, P1 } from './common/primitives';

export default function AppLanding() {
  const cardCTA = (
    <p className="flex gap-x-2 gap-y-3 justify-center flex-wrap lg:justify-start">
      <JoinWaitlist textOnMobile />
    </p>
  );

  const title = 'Dialect | Smart, interactive messages, NFT stickers, & more';
  const description = 'Join the waitlist for early access.';
  const linkPreview = `${CDN_URL}/link-previews/app-link-preview.png`;

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="description" content={description} />
        <meta property="twitter:title" content={title} />
        <meta property="og:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={linkPreview} />
        <meta property="twitter:image" content={linkPreview} />
      </Head>

      <main className="text-white overflow-hidden">
        <Header
          links={
            <>
              <Link
                href="https://developers.dialect.to"
                target="_blank"
                rel="noreferrer"
              >
                Developers
              </Link>
              <PopupButton
                id="VlrCEbUS"
                className={clsx('hover:text-white/60 transition-colors')}
              >
                Artists
              </PopupButton>
            </>
          }
          CTA={
            <div className="hidden sm:block">
              <JoinWaitlist textOnMobile />
            </div>
          }
        />
        <Hero className={'lg:mb-28'} />
        <Card
          className="mb-20"
          phoneContent={
            <LoopedVideo
              src={smartMessageDemoVideo}
              placeholderSrc={smartMessage.src}
            />
          }
          CTA={cardCTA}
        >
          <H2 className="lg:pr-10">Actions speak louder than words</H2>
          <P1>
            Smart Messaging makes it possible to send and receive tokens and
            interact with your favorite apps right from your messages and
            notifications
          </P1>
        </Card>
        <Card
          phoneOnRight
          className="mb-20"
          phoneContent={
            <LoopedVideo
              src={notificationsDemoVideo}
              placeholderSrc={notificationsDemoPoster}
            />
          }
          CTA={cardCTA}
        >
          <H2 className="lg:pr-10">Notifications, more actionable than ever</H2>
          <P1>
            Mint NFTs, vote on DAO proposals, make trades, & more with the tap
            of a button, right from your notifications
          </P1>
        </Card>
        <Card
          phoneContent={
            <LoopedVideo
              src={mintWithRevealNftVideo}
              placeholderSrc={nftStickers.src}
            />
          }
          CTA={cardCTA}
        >
          <H2>Non-fungible stickers are here</H2>
          <P1>
            Join the waitlist and start collecting stickers from the best
            artists across web3
          </P1>
        </Card>
        <CTA title={'Reserve your spot on the waitlist today'} CTA={cardCTA} />
        <Footer />
        <QRCodeModal />
      </main>
    </div>
  );
}
