import { LinkButton } from './common/Button';
import AppleIcon from '../icons/Apple.svg';
import AndroidIcon from '../icons/Android.svg';
import { APP_URL } from '../shared/constants';
import { useContext } from 'react';
import { QRCodeContext } from './context/QRCodeContext';
import { analyticsTrack } from '../shared/utils/analytics';

interface JoinWaitlistProps {
  textOnMobile?: boolean;
}

const JoinWaitlist = ({ textOnMobile }: JoinWaitlistProps) => {
  const { setOpen, modalEnabled } = useContext(QRCodeContext);

  return (
    <LinkButton
      href={APP_URL}
      target="_blank"
      left={<AppleIcon className="self-center" />}
      right={<AndroidIcon className="self-center" />}
      className="inline-flex items-baseline space-x-2"
      onClick={(e) => {
        analyticsTrack('button_click', {
          button: 'join_waitlist',
          href: modalEnabled ? undefined : APP_URL,
        });
        if (!modalEnabled) {
          return;
        }
        e.preventDefault();
        setOpen(true);
      }}
    >
      {textOnMobile ? (
        'Download'
      ) : (
        <span className="hidden lg:block">Download</span>
      )}
    </LinkButton>
  );
};

export default JoinWaitlist;
