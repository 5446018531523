import { GetServerSideProps } from 'next';
import AppLanding from '../components/AppLanding';
import { getDefaultServerSideProps, DefaultPageProps } from '../shared';
import Developers from './developers';
import LinkWalletPage from './link';

export default function Index(pageProps: DefaultPageProps) {
  if (pageProps.hostname === 'developers.dialect.to') {
    return <Developers />;
  }
  if (pageProps.hostname === 'link.dialect.to') {
    return <LinkWalletPage />;
  }
  return <AppLanding />;
}

export const getServerSideProps = getDefaultServerSideProps;
